import { graphql, navigate } from "gatsby"
import React from "react"
import dateFormat from "../utils/dateFormat"
import { Layout } from "@bw/layouts"
import { VaudMap, Teaser } from "@bw/partials"
import { CmsImage, Section, Grid, Seo, Button } from "@bw/bits"
import * as modules from "@bw/modules/resolvers"
import usePrefecturesCollection from "hooks/usePrefecturesCollection"

const PrefectureTemplate = ({ data, pageContext }) => {
  const { page, events } = data

  const { upcoming } = React.useMemo(() => {
    const now = new Date()

    return events.nodes.reduce(
      (acc, item) => {
        const itemdate = new Date(item.date)
        if (itemdate > now) {
          acc.upcoming.push(item)
        } else {
          acc.passed.push(item)
        }

        return acc
      },
      { upcoming: [], passed: [] }
    )
  }, [events])

  const prefectures = usePrefecturesCollection()

  const PageHeader = modules["PageHeader"]

  const clickOnMap = React.useCallback(
    e => {
      const prefecture = prefectures.find(
        prefecture => prefecture.district.value === e.target.id
      )

      if (prefecture) {
        navigate(prefecture.url)
      }
    },
    [prefectures]
  )

  return (
    <Layout {...{ pageContext }}>
      <Seo
        title={page.metaTitle || page.title}
        description={page.metaDescription}
        meta={[
          {
            name: "robots",
            content: page.doNotIndex
              ? "noindex, nofollow"
              : "max-image-preview:large, index, follow",
          },
        ]}
      />
      <PageHeader
        suptitle={<VaudMap onClick={clickOnMap} active={page.district.value} />}
        title={page.title}
      />
      {/*
       * Loading the Contents modules, ex Content or CallToAction, TeasersLatest
       */}
      {page.content
        .filter(module => module !== null)
        .map((module, i) => {
          const LoadedModule = modules[module.__typename.replace("Module", "")]
          if (typeof LoadedModule === "undefined") {
            return null
          }

          if (module.buttonType?.value === "url") {
            module.button = (
              <Button
                label={module.buttonText}
                href={module.buttonUrl}
                external={module.buttonIsExternal}
              />
            )
          }

          if (module.buttonType?.value === "entry") {
            module.button = (
              <Button label={module.buttonText} to={module.buttonEntry.url} />
            )
          }

          return <LoadedModule key={i} {...module} />
        })}
      {upcoming.length > 0 && (
        <Section title="Agenda">
          <Grid>
            {upcoming.map(event => {
              return (
                <Teaser
                  title={event.title}
                  suptitle={dateFormat(event.date)}
                  excerpt={event.teaserExcerpt}
                  to={event.url}
                  image={
                    event.teaserImage ? (
                      <CmsImage
                        src={event.teaserImage}
                        alt={event?.teaserImage?.alt}
                      />
                    ) : undefined
                  }
                />
              )
            })}
          </Grid>
        </Section>
      )}
    </Layout>
  )
}

export default PrefectureTemplate

export const prefectureQuery = graphql`
  query prefectureQuery($id: String!, $locale: String!) {
    events: allCollectionEvents(
      filter: {
        locale: { eq: $locale }
        prefectures: { elemMatch: { id: { eq: $id } } }
      }
      sort: { fields: date, order: ASC }
    ) {
      nodes {
        date
        teaserExcerpt
        teaserImage {
          url
        }
        title
        url
      }
    }
    page: collectionPrefectures(id: { eq: $id }) {
      title
      metaTitle
      metaDescription
      image {
        url
      }
      doNotIndex

      teaserImage {
        url
      }
      district {
        value
      }

      header {
        __typename
        suptitle
        title
        subtitle

        ... on HeroModule {
          ...HeroModuleFragment
        }
      }
      content {
        __typename
        suptitle
        title
        subtitle
        backgroundVariant
        buttonType {
          value
        }
        buttonUrl
        buttonIsExternal
        buttonText
        buttonPosition {
          value
        }
        buttonEntry {
          url
        }

        ... on CallToActionModule {
          ...CallToActionModuleFragment
        }
        ... on ContentModule {
          ...ContentModuleFragment
        }
        ... on ContentWithImageModule {
          ...ContentWithImageModuleFragment
        }
        ... on GalleryModule {
          ...GalleryModuleFragment
        }
        ... on ContentOnColumnsModule {
          ...ContentOnColumnsModuleFragment
        }
        ... on DownloadsModule {
          ...DownloadsModuleFragment
        }
        ... on TeasersLatestModule {
          ...TeasersLatestModuleFragment
        }
        ... on TeasersCustomModule {
          ...TeasersCustomModuleFragment
        }
        ... on TeasersManualModule {
          ...TeasersManualModuleFragment
        }
        ... on LogoGridModule {
          ...LogoGridModuleFragment
        }
        ... on ImageModule {
          ...ImageModuleFragment
        }
        ... on ImagesLinksModule {
          ...ImagesLinksModuleFragment
        }
        ... on VideoModule {
          ...VideoModuleFragment
        }
        ... on FormModule {
          ...FormModuleFragment
        }
      }
    }
  }
`
